<div class="h-100 w-full flex items-center justify-center font-sans">
	<div class="w-full p-4">
        <div class="mb-4">
            <div class="flex mt-4">
                <input [(ngModel)]="text" class="shadow appearance-none border rounded w-full py-2 px-3 mr-4 text-grey-darker" placeholder="Add Todo">
                <button (click)="addTodo()" class="flex-no-shrink p-2 border-2 rounded text-teal border-teal hover:text-white hover:bg-teal">Add</button>
            </div>
        </div>
        <div>
            <div *ngFor="let item of list" class="flex mb-4 items-center">
                <p class="w-full text-grey-900" [ngClass]="{'line-through text-green-600': item.done }">{{item.name}}</p>
                <button (click)="toDoService.flip(item)" class="flex-no-shrink p-2 ml-4 mr-2 border-2 rounded hover:text-white text-green border-green hover:bg-green">{{item.done ? "Revert" : "Done"}}</button>
                <button (click)="toDoService.remove(item)" class="flex-no-shrink p-2 ml-2 border-2 rounded text-red border-red hover:text-white hover:bg-red">Remove</button>
            </div>
        </div>
    </div>
</div>