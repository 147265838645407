<div class="flex-1">      
      <div class="w-full text-center">
          <p class="text-sm tracking-widest text-gray-500">Built in a day | Built to last</p>
          <h1 class="font-bold text-5xl text-gray-700">
              Utilities
          </h1>
      </div>
              
      <nav class="grid grid-cols-1 gap-4 sm:grid-cols-3 ">
  
          <a routerLink="/shrt" class="p-2 sm:p-10 text-center cursor-pointer">
              <div class="py-16 max-w-sm rounded overflow-hidden shadow-lg hover:bg-white transition duration-500  bg-white">
                  <div class="space-y-10">
                      <i class="fa fa-spa" style="font-size:48px;"></i>
                      
                      <div class="px-6 py-4">
                          <div class="space-y-5">
                              <div class="font-bold text-xl mb-2">shrt</div>
                              <p class="text-gray-700 text-base">
                                  URL Shortener
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          </a>
  
          <a routerLink="/app" class="p-2 sm:p-10 text-center cursor-pointer text-white"> 
              <div class="py-16 max-w-sm rounded overflow-hidden shadow-lg bg-green-800 hover:bg-green-900 transition duration-500">
                  <div class="space-y-10">
                    <i class="fa fa-head-side-mask" style="font-size:48px;"></i>
                      <div class="px-6 py-4">
                          <div class="space-y-5">
                              <div class="font-bold text-xl mb-2">To-Do</div>
                              <p class="text-base">
                                  Simple To-Do App
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          </a>
  
          <a routerLink="/kanban" class="p-2 sm:p-10 text-center cursor-pointer translate-x-2">
              <div class="py-16 max-w-sm rounded overflow-hidden shadow-lg hover:bg-white transition duration-500 bg-white ">
                  <div class="space-y-10">
                      <i class="fa fa-swimmer" style="font-size:48px;"></i>
                      
                      <div class="px-6 py-4">
                          <div class="space-y-5">
                              <div class="font-bold text-xl mb-2">Kanban</div>
                              <p class="text-gray-700 text-base">
                                  Simple Trello Cards
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          </a>
  
      </nav>
  
  </div>